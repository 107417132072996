/// <reference path="./custom-window.d.ts" />

import { hideInputData, setButtonTitle, setLabelText, setSelectInputData, setTextInputData } from "./Utils";

window.PLDialogOrderInstall = function (target, trigger, json, fulfillmentHeader, instructionsHeader, separator) {
  console.log('Dialog Order install, target=' + target + ', trigger=' + trigger + ', json=' + json + ', fulfillmentHeader=' + fulfillmentHeader + ', instructionsHeader=' + instructionsHeader);

  const dialog = "pl-globals-order-dialog";
  const title = dialog + separator + "title";
  const fulfillment = dialog + separator + "fulfillment";
  const instructions = dialog + separator + "instructions";
  const positive = dialog + separator + "positive";
  const negative = dialog + separator + "negative";

  const data = JSON.parse(json);

  const dialogElm = document.getElementById(dialog) as HTMLDialogElement;
  const fulfillmentSel = document.getElementById(fulfillment) as HTMLSelectElement;
  const instructionsInp = document.getElementById(instructions) as HTMLInputElement;
  instructionsInp.style.display = "none";
  const positiveBtn = document.getElementById(positive);
  const negativeBtn = document.getElementById(negative);

  setLabelText(title, separator, data.title);
  setSelectInputData(fulfillment, separator, data.fulfillment);
  setButtonTitle(positive, separator, data.positive);
  setButtonTitle(negative, separator, data.negative);

  fulfillmentSel?.addEventListener("change", (event) => {
    const target = event.target as HTMLSelectElement;
    const fulfillmentValue = target.value;
    data.instructions.items.forEach((item: { header: string, title: string, isVisible: boolean }) => {
      if (item.isVisible && item.header === fulfillmentValue) {
        setTextInputData(instructions, separator, data.instructions);
        instructionsInp.style.display = "block";
      } else {
        hideInputData(instructions, separator);
        instructionsInp.style.display = "none";
      }
    })
  })
  // @ts-ignore
  dialogElm.showModal();

  positiveBtn?.addEventListener("click", () => {
    const callback = document.getElementById(target);
    if (callback) {
      const fulfillmentValue = fulfillmentSel.options[fulfillmentSel.selectedIndex];
      const instructionsValue = instructionsInp.value;
      const jsonValue = JSON.stringify({
        [fulfillmentHeader]: fulfillmentValue.value,
        [instructionsHeader]: instructionsValue
      });
      callback.setAttribute("hx-vals", jsonValue)
      window.htmx.trigger(callback, trigger);
    }
    hideInputData(instructions, separator);
    instructionsInp.value = "";
    instructionsInp.style.display = "none";
    // @ts-ignore
    dialogElm.close();
  })
  // @ts-ignore
  negativeBtn?.addEventListener("click", () => { dialogElm.close(); });
};

console.log('PLDialogs has been installed');
