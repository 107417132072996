/// <reference path="./custom-window.d.ts" />

window.PLBadge = function (oldId, newId, amount) {
  console.log("oldId= " + oldId + " newId= " + newId + " amount= " + amount)
  const newParent = document.getElementById(newId);
  const oldParent = document.getElementById(oldId) as HTMLElement;

  if (newParent !== null && oldParent !== null) {
    newParent.style.position = 'relative';
    const children = newParent.querySelectorAll("span")
    children.forEach(child => child.remove())

    const firstChild = oldParent.firstElementChild;
    if (firstChild) {
      newParent.appendChild(firstChild);
    }

    if (amount === "0") {
      const children = newParent.querySelectorAll("span")
      children.forEach(child => child.remove())
    }
  }
}

console.log("PLBadge installed")
