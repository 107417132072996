/// <reference path="./custom-window.d.ts" />
import * as FilePond from 'filepond';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

interface HtmxConfigRequestEvent extends Event {
  detail: {
    parameters: FormData;
  };
}

window.PLDragAndDropInstall = function (id, title, accept, isDisabled, icon, separator) {
  console.log("PLDragAndDrop id= " + id, " isDisabled= " + isDisabled)
  const form = document.getElementById(id) as HTMLFormElement;
  const inputElement = form?.querySelector('input[type="file"]') as HTMLInputElement;
  const container = form?.querySelector('#filepondContainer') as HTMLElement;
  const toaste = document.getElementById(id + separator + "toast") as HTMLElement;

  if (toaste)
    toaste.style.display = 'none'


  if (inputElement && form) {
    container.style.opacity = '1';
    const maxFileSize = '15mb'; // techStorage size limit

    FilePond.registerPlugin(
      FilePondPluginImagePreview,
      FilePondPluginFileEncode,
      FilePondPluginFileValidateSize,
      FilePondPluginImageExifOrientation,
      FilePondPluginFileValidateType);

    const pond = FilePond.create(inputElement, {
      credits: false, // Should link to https://pqina.nl somewere on the page when credits are disabled. https://pqina.nl/filepond/docs/api/instance/properties/#disabling-credits
      dropOnPage: true,
      allowMultiple: true,
      allowDrop: true,
      labelIdle: `<div id="filepondWrapper">${icon ? `<img src=${icon} alt="Ikon ladda upp" style="display: block;">` : ""}  ${title} <span class="filepond--label-action"> Välj filer </span></div>`,
      disabled: isDisabled,
      labelFileTypeNotAllowed: "Filen är inte av rätt typ",
      acceptedFileTypes: accept,
      allowFileSizeValidation: true,
      maxFileSize: `${maxFileSize}`,
      maxTotalFileSize: `${maxFileSize}`,
      labelMaxFileSizeExceeded: 'Fil är för stor',
      labelMaxFileSize: `Fil är för stor, max storlek är ${maxFileSize}`,
      labelMaxTotalFileSizeExceeded: `Filerna är för stora, max storlek är ${maxFileSize}`,
      labelMaxTotalFileSize: `Filerna är för stora, max storlek är ${maxFileSize}`,
      server: {
        process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
          toaste.style.display = 'block'
          window.htmx.trigger('#' + id, 'submit')
        },
      }
    });

    form.addEventListener("htmx:configRequest", ((event: Event) => {
      console.log("HTMX config request triggered");
      const htmxEvent = event as HtmxConfigRequestEvent;
      const formData = htmxEvent.detail.parameters;
      const newFormData = new FormData();

      for (const [key, value] of formData.entries()) {
        if (value !== null && value !== undefined && value !== '') {
          newFormData.append(key, value);
        }
      }

      pond.getFiles().forEach((fileItem) => {
        newFormData.append('file', fileItem.file);
      });

      pond.disabled = true
      htmxEvent.detail.parameters = newFormData;
    }));
  }

  window.addEventListener('popstate', function (event) {
    if (inputElement)
      location.reload();
  });
}
console.log('PLDragAndDrop has been installed')