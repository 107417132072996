/// <reference path="./custom-window.d.ts" />
import { Toast } from "bootstrap";

window.PLToastShow = function (id, separator) {
  console.log('Toast show, id=' + id);

  const toastElement = document.getElementById(id);
  if (toastElement) {
    const toastBootstrap = Toast.getOrCreateInstance(toastElement);
    toastBootstrap.show();

    const autoHide = toastElement.getAttribute("data-bs-autohide") !== "false";

    if (autoHide) {
      setTimeout(() => {
        toastBootstrap.hide();

        setTimeout(() => {
          toastElement.remove();
          console.log("Toast removed, id=" + id);
        }, 300);
      }, 5000);
    }
  }
}
console.log('PLToast has been installed');
