window.PLListGroupInstall = function (id, optionsId, textId, addId, plainItem, booleanItem, listGroup, sep) {
  const group = document.getElementById(id + sep + 'group');
  const text = document.getElementById(textId);
  const options = document.getElementById(optionsId);
  const add = document.getElementById(addId);
  const booleanClone = document.getElementById(id + sep + 'boolean-clone' + sep + listGroup);
  const plainItemClone = document.getElementById(id + sep + 'plain-item-clone' + sep + listGroup);
  const numberItemClone = document.getElementById(id + sep + 'number-item-clone' + sep + listGroup);

  function removeBtn(element, value, optionId) {
    let closeBtn = element.getElementsByClassName('btn')[0];
    closeBtn.setAttribute("onClick", `PLListGroupRemoveRow('${value}', '${optionId}')`)
  }

  add.addEventListener('click', function () {
    let valueAndType;
    let input;
    let selectValue;
    let selectValueTitle;
    if (text != null && text.value !== "") {
      valueAndType = [text.value, text.id.split(sep)[1]]
      input = text;
    } else {
      selectValue = options.value;
      selectValueTitle = options.options[options.selectedIndex].innerHTML;
      let optionSplit = selectValue.split(sep)
      valueAndType = [optionSplit[1], optionSplit[2]]
      input = options;
    }

    const {value, type, trailing} = filtered(valueAndType)

    if (selectValue === undefined) {
      selectValue = id + sep + value + sep + type;
      selectValueTitle = text.value;
    }

    let currentValues = PLListGroupUpdateOptions(optionsId).map((v) => v.toLowerCase())

    if (value !== undefined && !currentValues.includes(selectValue.toLowerCase())) {
      if (value && input.checkValidity()) {
        let element;
        if (type === plainItem) {
          element = plainItemClone.cloneNode(true);
          let inputHidden = element.getElementsByClassName('pl-list-group-input')[0];
          inputHidden.setAttribute("name", id + sep + value);

        } else if (type === booleanItem) {
          element = booleanClone.cloneNode(true);
        } else {
          element = numberItemClone.cloneNode(true);

          let trailingDiv = element.getElementsByClassName('input-group-text')[0];
          if (trailing !== "") {
            trailingDiv.innerText = trailing;
          } else {
            trailingDiv.remove()
          }

          const numberInputForTrailing = element.getElementsByClassName('form-control')[0];
          numberInputForTrailing.setAttribute("name", id + sep + value);
          numberInputForTrailing.setAttribute("id", id + sep + value);
          numberInputForTrailing.addEventListener("change", () => {
            numberInputForTrailing.setAttribute("value", numberInputForTrailing.value);
          });
          numberInputForTrailing.setAttribute("aria-describedby", id + sep + value + '.hint');
        }
        // Give remove button the right target
        removeBtn(element, id + sep + value + sep + listGroup, optionsId);

        // Give the clone values
        element.setAttribute("id", id + sep + value + sep + listGroup);
        element.setAttribute("style", "");
        element.setAttribute("data-value", selectValue);

        let formCheckInput = element.querySelectorAll('.form-check-input');
        formCheckInput.forEach((p) => {
          p.setAttribute("name", id + sep + value)
        })

        let valueHidden = element.getElementsByClassName('pl-list-group-value')[0];
        valueHidden.textContent = selectValueTitle;

        group.appendChild(element);
        PLListGroupUpdateOptions(optionsId);

        if (text) text.value = null;
        if (options) options.selectedIndex = 0;
      }
    } else if (text) {
      text.style.borderColor = "red";
      text.value = "";
      setTimeout(() => {
        text.removeAttribute("style");
      }, 3000);
    } else {
      options.style.borderColor = "red"
      setTimeout(() => {
        options.removeAttribute("style")
      }, 3000)
    }
  });

  window.PLListGroupRemoveRow = function (id, optionsId) {
    const element = document.getElementById(id);
    const parent = element.parentNode;
    parent.removeChild(element);
    PLListGroupUpdateOptions(optionsId);
  };

  window.PLListGroupUpdateOptions = function (id) {
    let currentValues = []
    let currentValuesElements = document.querySelectorAll('.list-group-item')
    currentValuesElements.forEach((p) => {
      if (p.dataset.value) {
        currentValues.push(p.dataset.value)
      }
    })
    const selectsOptions = document.getElementById(id)
    if (selectsOptions != null) {
      for (let i = 0; i < selectsOptions.options.length; i++) {
        let option = selectsOptions.options[i]
        if (currentValues.includes(option.value)) {
          option.style.display = 'none'
        } else {
          option.style.display = ''
        }
      }
    }

    return currentValues
  }

  function filtered(valueAndType) {
    if (valueAndType[1].includes("|")) {
      let typeAndTrailing = valueAndType[1].split("|")
      return {value: valueAndType[0], type: typeAndTrailing[0], trailing: typeAndTrailing[1]};
    } else {
      return {value: valueAndType[0], type: valueAndType[1], trailing: null}
    }
  }

  PLListGroupUpdateOptions(optionsId);
};

console.log('PLListGroup has been installed');
