/// <reference path="./custom-window.d.ts" />
import { Canvas, StaticCanvas, FabricImage, FabricObject } from 'fabric';
import { EraserBrush } from './EraserBrush';
import { MaskBrush } from './MaskBrush';
import {
  CanvasComponent,
  scaleObject,
  removeBrushes,
  createLoading,
  fadeInImages, animateLoading, fadeOutImages, setImageScale,
} from './FabricUtils';
import { activateButton, hardcoded, inactivateButton, swallow, updateRoot } from "./Utils";

interface ImageEditorState {
  id: string;
  project: string;
  size: [number, number];

  // Elements
  container: HTMLElement;
  root: HTMLElement;
  canvas: Canvas;
  canvasComponent: CanvasComponent;

  // Layers
  firstImageLayerId: number;
  secondImageLayerId: number;
  maskLayerId: number;
  loadingLayerId: number;

  // Identifiers
  panId: string;
  brushId: string;
  opacityId: string;
  sizingId: string;

  // Modes
  isDisabled: boolean;
  isMasking: boolean;
  isPositioning: boolean;
  isErasing: boolean;
  isComparing: boolean;

  // Zooming and panning
  isPanning: boolean;
  isMouseDown: boolean;
  zoomInitial: number;
  zoomLevel: number;
  moveX: number;
  moveY: number;
  previousX: number;
  previousY: number;

  // Images
  firstImage: [string, FabricImage, string] | undefined;
  secondImage: [string, FabricImage, string] | undefined;
  assetType: number;

  // Graphics
  loading: FabricObject;
}

let imageEditorStates: { [id: string]: ImageEditorState } = {};

window.PLImageEditorInstall = function (id, project, assetType, size, first, second, isDisabled, isMasking, isPositioning, isErasing, isComparing, separator) {
  console.log('Image Editor install, id=' + id + ', project=' + project + ', assetType=' + assetType + ', size=[' + size[0] + ',' + size[1] + '], first=' + first + ', second=' + second + ', isDisabled=' + isDisabled + ', isMasking=' + isMasking + ', isPositioning=' + isPositioning + ', isErasing=' + isErasing + ', isComparing=' + isComparing);

  const canvas = new Canvas(id + separator + 'drawing');
  const canvasComponent = new CanvasComponent(canvas);

  // Layers (In order of appearance)
  const firstImageLayerId = canvasComponent.addLayer(false);
  const secondImageLayerId = canvasComponent.addLayer(false);
  const maskLayerId = canvasComponent.addLayer(false);
  const loadingLayerId = canvasComponent.addLayer(false);

  canvas.preserveObjectStacking = true;
  canvas.backgroundColor = 'white';

  const state: ImageEditorState = {
    id: id,
    size: size,
    project: project,

    // Elements
    container: document.getElementById(id + separator + 'container') as HTMLElement,
    root: document.getElementById(id + separator + 'root') as HTMLElement,
    canvas: canvas,
    canvasComponent: canvasComponent,

    // Layers
    firstImageLayerId: firstImageLayerId,
    secondImageLayerId: secondImageLayerId,
    maskLayerId: maskLayerId,
    loadingLayerId: loadingLayerId,

    // Identifiers
    panId: id + separator + 'zoom' + separator + 'pan',
    brushId: id + separator + 'tools' + separator + 'brush',
    opacityId: id + separator + 'tools' + separator + 'opacity',
    sizingId: id + separator + 'tools' + separator + 'sizing',

    // Mode
    isDisabled: isDisabled,
    isMasking: isMasking,
    isPositioning: isPositioning,
    isErasing: isErasing,
    isComparing: isComparing,

    // Zooming and panning
    isPanning: false,
    isMouseDown: false,
    zoomInitial: 0.5,
    zoomLevel: 0.5,
    moveX: 0,
    moveY: 0,
    previousX: 0,
    previousY: 0,

    // Images
    firstImage: undefined,
    secondImage: undefined,
    assetType: assetType,

    // Graphics
    loading: createLoading(canvasComponent, loadingLayerId),
  }

  imageEditorStates[id] = state;

  updateRoot(state.root, state.zoomLevel, [state.moveX, state.moveY]);

  setupInteractiveControls(state);
  setupBrushControls(state);

  setImages(state, first, second, isPositioning).then();
  toggleMasking(state);
  toggleProgress(state);

  canvas.requestRenderAll();
};

window.PLImageEditorUpdate = function (id, first, second, isDisabled, isMasking, isPositioning, isErasing, isComparing) {
  console.log('Image Editor update, id=' + id + ', first=' + first + ', second=' + second + ', isDisabled=' + isDisabled + ', isMasking=' + isMasking + ', isPositioning=' + isPositioning + ', isErasing=' + isErasing + ', isComparing=' + isComparing);
  let state = imageEditorStates[id];
  state.isDisabled = isDisabled;
  state.isMasking = isMasking;
  state.isErasing = isErasing;
  state.isComparing = isComparing;
  setImages(state, first, second, isPositioning).then(() => {
    state.isPositioning = isPositioning;

    toggleMasking(state);
    toggleProgress(state);
    state.canvas.requestRenderAll();
  });
};

window.PLImageEditorFeedback = function (id, message, isVisible) {
  console.log('Image Editor feedback, id=' + id + ', message=' + message + ', isVisible=' + isVisible);
  let state = imageEditorStates[id];
  let label = state.root.querySelector('p');
  let parent = label?.parentNode as HTMLElement;
  if (label && parent) {
    if (isVisible) {
      parent.style.display = "block";
      label.style.display = "block";
      label.textContent = message;
    } else {
      parent.style.display = "none";
      label.style.display = "none";
    }
  }
};

window.PLImageEditorTogglePanning = function (id, event) {
  let state = imageEditorStates[id];
  if (state.isPanning) {
    stopPanning(state);
    state.isPanning = false;
  } else {
    startPanning(state);
    state.isPanning = true;
  }
  state.container.focus(); // Always focus container to fix event handlers
}

window.PLImageEditorZoom = function (id, event, delta) {
  let state = imageEditorStates[id];
  state.zoomLevel *= 0.999 ** delta;
  if (state.zoomLevel > 20) state.zoomLevel = 20;
  if (state.zoomLevel < 0.01) state.zoomLevel = 0.01;
  updateRoot(state.root, state.zoomLevel, [state.moveX, state.moveY]);
  state.container.focus(); // Always focus container to fix event handlers
};

window.PLImageEditorZoomReset = function (id, event) {
  let state = imageEditorStates[id];
  state.zoomLevel = state.zoomInitial;
  updateRoot(state.root, state.zoomLevel, [state.moveX, state.moveY]);
  state.container.focus(); // Always focus container to fix event handlers
};

window.PLImageEditorSize = function (id, event, delta) {
  let state = imageEditorStates[id];
  state.container.focus(); // Always focus container to fix event handlers
};

window.PLImageEditorUndo = function (id, event) {
  let state = imageEditorStates[id];
  const objects = state.canvasComponent.getLayer(state.maskLayerId)?.objects ?? [];
  if (objects.length > 0) {
    state.canvasComponent.removeObjectFromLayer(state.maskLayerId, objects[objects.length - 1]);
  }
  if (state.canvas.freeDrawingBrush instanceof EraserBrush) {
    state.canvas.freeDrawingBrush.reset()
  }
  state.canvas.requestRenderAll();
  state.container.focus(); // Always focus container to fix event handlers
};

window.PLImageEditorClear = function (id, event) {
  let state = imageEditorStates[id];
  state.canvasComponent.clearLayer(state.maskLayerId);
  state.canvas.requestRenderAll();
  state.container.focus(); // Always focus container to fix event handlers
};

window.PLImageEditorSetMask = async function (id, event) {
  let state = imageEditorStates[id];
  toggleProgress(state, true);

  const blob = await getMaskImage(state);
  if (blob) {
    await uploadSupport(state, blob, 'mask', event);
  }

  toggleProgress(state, false);
};

window.PLImageEditorRemoveMask = async function (id, event) {
  let state = imageEditorStates[id];
  toggleProgress(state, true);

  const formData = new FormData();
  formData.append('type', 'mask');
  formData.append('project', state.project);
  formData.append('assetType', state.assetType.toString());
  if (state.secondImage?.[0]) {
    formData.append('asset', state.secondImage?.[0]);
  }
  const response = await fetch('/image-editor-remove', {
    method: 'POST',
    body: formData
  });

  if (response.ok) {
    window.htmx.trigger('#image-editor-refresh-' + id, 'click');
    state.canvasComponent.clearLayer(state.maskLayerId);
  } else {
    const errorText = await response.text();
    alert(errorText);
  }

  toggleProgress(state, false);
  state.container?.focus(); // Always focus container to fix event handlers
};

window.PLImageEditorSave = async function (id, event) {
  let state = imageEditorStates[id];
  toggleProgress(state, true);

  if (state.isErasing) {
    const blob = await getMergedImage(state);
    if (blob) {
      await uploadResult(state, blob);
    }
  } else {
    await moveResult(state);
  }

  toggleProgress(state, false);
  state.container?.focus(); // Always focus container to fix event handlers
};

window.PLImageEditorDiscard = async function (id, event) {
  let state = imageEditorStates[id];
  toggleProgress(state, true);

  const formData = new FormData();
  formData.append('type', 'result');
  formData.append('project', state.project);
  formData.append('assetType', state.assetType.toString());
  if (state.secondImage?.[0]) {
    formData.append('asset', state.secondImage?.[0]);
  }
  const response = await fetch('/image-editor-remove', {
    method: 'POST',
    body: formData
  });

  if (response.ok) {
    window.htmx.trigger('#image-editor-refresh-' + id, 'click');
  } else {
    const errorText = await response.text();
    alert(errorText);
  }

  toggleProgress(state, false);
  state.container?.focus(); // Always focus container to fix event handlers
};

window.PLImageEditorDownload = async function (id, event) {
  let state = imageEditorStates[id];

  if (state.isErasing) {
    const width = state.size[0];
    const height = state.size[1];
    const upload = new StaticCanvas(undefined, {
      width: width,
      height: height,
      backgroundColor: 'black',
      enableRetinaScaling: false
    });

    const scale: [number, number] = [width / state.canvas.width, height / state.canvas.height];

    const img1 = state.firstImage ?
      state.firstImage[1].clone().then((obj) => {
        scaleObject(obj, scale);
        upload.insertAt(0, obj);
      })
      : Promise.resolve(null);

    const img2 = state.secondImage ?
      state.secondImage[1].clone().then((obj) => {
        scaleObject(obj, scale);
        upload.insertAt(1, obj);
      })
      : Promise.resolve(null);

    await Promise.all([img1, img2]);

    upload.renderAll();

    const blob = await new Promise<Blob | null>(resolve => upload.getElement().toBlob(resolve));
    if (blob) {
      window.PLDownloadImage(blob, null)
    }
  } else if (state.secondImage?.[2]) {
    const a = document.createElement('a');
    a.href = state.secondImage?.[2];
    a.download = hardcoded('Result.png');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  state.container?.focus(); // Always focus container to fix event handlers
};

window.PLDownloadImage = async function (blob, imageUrl) {
  if (blob) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = hardcoded('Result.png');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  } else {
    const response = await fetch(imageUrl ? imageUrl : "");
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    if (blob.type === 'video/mp4') {
      a.download = hardcoded('Result.mp4');
    }
    else
      a.download = hardcoded('Result.png');

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }
}

window.PLImageEditorPosition = async function (id, event) {
  let state = imageEditorStates[id];
  toggleProgress(state, true);

  const blob = await getPositionedImage(state);
  if (blob) {
    await uploadSupport(state, blob, 'positioning', event);
  }

  toggleProgress(state, false);
  state.container?.focus(); // Always focus container to fix event handlers
};

async function setImages(state: ImageEditorState, first: [string, string] | undefined, second: [string, string] | undefined, isPositioning: boolean): Promise<void> {
  let updateImages = first?.[0] !== state.firstImage?.[0] || second?.[0] !== state.secondImage?.[0] || isPositioning !== state.isPositioning;
  if (updateImages) {
    await fadeOutImages(state.canvasComponent, [state.firstImage?.[1], state.secondImage?.[1]]);

    const firstImg = await setFirstImage(state, first, isPositioning);
    const secondImg = await setSecondImage(state, second, isPositioning);

    let images: [FabricImage, number, number][] = [];

    if (firstImg) {
      images.push([firstImg, 0, 1]);
    } else if (state.firstImage && state.firstImage[1].opacity < 1) {
      images.push([state.firstImage?.[1], 0, 1]);
    }
    if (secondImg) {
      images.push([secondImg, 1, state.isMasking ? 0.5 : 1]);
    }

    await fadeInImages(state.canvasComponent, images);
  }

  installOpacityControls(state);
  installSizingControls(state);
  installBrushControls(state);
}

async function setFirstImage(state: ImageEditorState, image: [string, string] | undefined, isPositioning: boolean): Promise<FabricImage | undefined> {
  const [id1, img1] = state.firstImage ?? []
  const [id2] = image ?? []

  let img: FabricImage | undefined = undefined;

  if (id1 !== id2) {
    state.canvasComponent.clearLayer(state.firstImageLayerId);

    if (image && image[1] && image[1] !== '') {
      img = await loadImage(state, image, isPositioning);
      state.firstImage = [image[0], img, image[1]];

      state.canvasComponent.addObjectToLayer(state.firstImageLayerId, img);
    } else {
      state.firstImage = undefined
    }
  } else if (img1) {
    adjustImage(state, img1, isPositioning);
  }

  if (state.firstImage) {
    state.canvasComponent.showLayer(state.firstImageLayerId);
  } else {
    state.canvasComponent.hideLayer(state.firstImageLayerId);
  }

  return img;
}

async function setSecondImage(state: ImageEditorState, image: [string, string] | undefined, isPositioning: boolean): Promise<FabricImage | undefined> {
  const [id1, img1] = state.secondImage ?? []
  const [id2] = image ?? []

  let img: FabricImage | undefined = undefined;

  if (id1 !== id2) {
    state.canvasComponent.clearLayer(state.secondImageLayerId);

    if (image && image[1] && image[1] !== '') {
      img = await loadImage(state, image, isPositioning);
      state.secondImage = [image[0], img, image[1]];

      state.canvasComponent.addObjectToLayer(state.secondImageLayerId, img);
    } else {
      state.secondImage = undefined
    }
    // Some brushes have dependency on second image
    removeBrushes(state.canvas);
  } else if (img1) {
    adjustImage(state, img1, isPositioning);
  }

  if (state.secondImage) {
    state.canvasComponent.showLayer(state.secondImageLayerId);
  } else {
    state.canvasComponent.hideLayer(state.secondImageLayerId);
  }

  return img;
}

async function loadImage(state: ImageEditorState, image: [string, string], isPositioning: boolean): Promise<FabricImage> {
  const img = await FabricImage.fromURL(image[1], { 'crossOrigin': 'anonymous' });
  img.opacity = 0;
  img.set('erasable', true);
  adjustImage(state, img, isPositioning);
  return img;
}

function adjustImage(state: ImageEditorState, image: FabricImage, isPositioning: boolean) {
  if (!isPositioning) {
    image.set({ left: 0, top: 0, angle: 0 });
    image.hasControls = false;
    image.evented = false;
    image.selectable = false;

    const canvasAspect = state.canvas.width / state.canvas.height;
    const imageAspect = image.width! / image.height!;

    if (canvasAspect > imageAspect) {
      image.scaleToWidth(state.canvas.width);
      const overflow = (image.getScaledHeight() - state.canvas.height) / 2;
      image.set({ top: -overflow });
    } else {
      image.scaleToHeight(state.canvas.height);
      const overflow = (image.getScaledWidth() - state.canvas.width) / 2;
      image.set({ left: -overflow });
    }
  } else {
    image.hasControls = true;
    image.evented = true;
    image.selectable = true;
    image.scale(1);
    image.scale(1);
  }
}

function toggleProgress(state: ImageEditorState, isDisabled: boolean | undefined = undefined) {
  if (isDisabled) state.isDisabled = isDisabled;
  if (state.isDisabled) {
    state.canvasComponent.showLayer(state.loadingLayerId);
    animateLoading(state.canvasComponent, state.loadingLayerId, state.loading);
  } else {
    state.canvasComponent.hideLayer(state.loadingLayerId);
  }
  state.canvas.requestRenderAll();
}

function toggleMasking(state: ImageEditorState) {
  if (state.isMasking) {
    state.canvasComponent.showLayer(state.maskLayerId);
  } else {
    state.canvasComponent.hideLayer(state.maskLayerId);
  }
  state.canvas.requestRenderAll();
}

function installBrushControls(state: ImageEditorState) {
  const brush = document.getElementById(state.brushId);
  updateBrushControls(state);
  brush?.addEventListener('input', function (e) {
    updateBrushControls(state);
    swallow(e);
  });
}

function installOpacityControls(state: ImageEditorState) {
  const opacity = document.getElementById(state.opacityId);
  updateOpacityControls(state);
  opacity?.addEventListener('input', function (e) {
    updateOpacityControls(state);
    swallow(e);
  });
}

function installSizingControls(state: ImageEditorState) {
  const sizing = document.getElementById(state.sizingId);
  updateSizingControls(state);
  sizing?.addEventListener('input', function (e) {
    updateSizingControls(state);
    swallow(e);
  });
}

function setupBrushControls(state: ImageEditorState) {
  // Brush path handling
  state.canvas.on('path:created', function (e) {
    const path = e.path;
    // Turn off selection for paths created during free drawing mode
    e.path.set({
      evented: false,
      hasControls: false,
      selectable: false
    });

    // Path recording for undo
    state.canvasComponent.addObjectToLayer(state.maskLayerId, path, true);
  });
}

function updateOpacityControls(state: ImageEditorState) {
  const opacity = document.getElementById(state.opacityId) as HTMLInputElement;
  if (state.isComparing && state.secondImage) {
    state.secondImage[1].set({ opacity: (+opacity?.value || 100) / 100 });
    state.canvas.requestRenderAll();
  } else if (state.secondImage && state.isMasking) {
    state.secondImage[1].set({ opacity: 0.5 });
    state.canvas.requestRenderAll();
  }
}

function updateSizingControls(state: ImageEditorState) {
  const sizing = document.getElementById(state.sizingId) as HTMLInputElement;
  if (state.firstImage && sizing && state.isPositioning) {
    setImageScale(state.firstImage[1], +sizing?.value || 50);
  }
  state.canvas.requestRenderAll();
}

function setupInteractiveControls(state: ImageEditorState) {
  // Zooming
  state.container.addEventListener('wheel', function (e) {
    if (e.ctrlKey || e.metaKey) {
      window.PLImageEditorZoom(state.id, e, e.deltaY);
    }
  });

  // Panning
  state.container.addEventListener('keydown', function (e) {
    if (e.code === 'Space') {
      startPanning(state);
      state.isPanning = true;
      swallow(e);
    }
  });
  state.container.addEventListener('keyup', function (e) {
    if (e.code === 'Space') {
      stopPanning(state);
      state.isPanning = false;
      swallow(e);
    }
  });
  state.container.onmousedown = function (e) {
    if (state.isPanning) {
      state.isMouseDown = true;
      state.previousX = e.clientX;
      state.previousY = e.clientY;
      swallow(e);
    }
  };
  state.container.onmouseup = function () {
    state.isMouseDown = false;
  };
  state.container.onmousemove = function (e) {
    if (state.isPanning && state.isMouseDown) {
      state.moveX += (e.clientX - state.previousX) / state.zoomLevel;
      state.moveY += (e.clientY - state.previousY) / state.zoomLevel;
      state.previousX = e.clientX;
      state.previousY = e.clientY;
      updateRoot(state.root, state.zoomLevel, [state.moveX, state.moveY]);
      swallow(e);
    }
  };
}

function updateBrushControls(state: ImageEditorState) {
  const brush = document.getElementById(state.brushId) as HTMLInputElement;
  if (state.isErasing && !state.isDisabled && state.secondImage) {
    if (!(state.canvas.freeDrawingBrush instanceof EraserBrush)) {
      removeBrushes(state.canvas);
      state.canvas.freeDrawingBrush = new EraserBrush(state.canvas, state.secondImage[1], state.container);
    }
    if (state.canvas.freeDrawingBrush) {
      state.canvas.freeDrawingBrush.width = +brush?.value || 50;
    }
    state.canvas.isDrawingMode = true;
  } else if (state.isMasking && !state.isDisabled && !state.secondImage) {
    if (!(state.canvas.freeDrawingBrush instanceof MaskBrush)) {
      removeBrushes(state.canvas);
      state.canvas.freeDrawingBrush = new MaskBrush(state.canvas, state.container);
    }
    state.canvas.freeDrawingBrush.color = "rgba(48, 92, 249, 0.4)";
    state.canvas.freeDrawingBrush.width = +brush?.value || 50;
    state.canvas.isDrawingMode = true;
  } else {
    removeBrushes(state.canvas);
    state.canvas.isDrawingMode = false;
  }
}

function startPanning(state: ImageEditorState) {
  removeBrushes(state.canvas);
  state.canvas.isDrawingMode = false;
  state.canvas.defaultCursor = 'grab';
  activateButton(state.panId);
  state.container.style.cursor = 'grab';
}

function stopPanning(state: ImageEditorState) {
  updateBrushControls(state);
  state.canvas.defaultCursor = 'default';
  inactivateButton(state.panId);
  state.container.style.cursor = 'default';
}

async function getMaskImage(state: ImageEditorState): Promise<Blob | null> {
  const width = state.size[0];
  const height = state.size[1];
  const upload = new StaticCanvas(undefined, {
    width: width,
    height: height,
    backgroundColor: 'black',
    enableRetinaScaling: false
  });

  const scale: [number, number] = [width / state.canvas.width, height / state.canvas.height];

  const objects = state.canvasComponent.getLayer(state.maskLayerId)?.objects ?? [];
  await Promise.all(objects.map(async (objectId) => {
    const path = state.canvasComponent.getObjectById(objectId);
    if (path) {
      const obj = await path.clone();
      obj.set({ stroke: 'white' });
      scaleObject(obj, scale);
      upload.add(obj);
    }
  }));

  upload.renderAll();

  return new Promise<Blob | null>(resolve => upload.getElement().toBlob(resolve));
}

async function getMergedImage(state: ImageEditorState): Promise<Blob | null> {
  const width = state.size[0];
  const height = state.size[1];
  const upload = new StaticCanvas(undefined, {
    width: width,
    height: height,
    backgroundColor: 'black',
    enableRetinaScaling: false
  });

  const scale: [number, number] = [width / state.canvas.width, height / state.canvas.height];

  const img1 = state.firstImage ?
    state.firstImage[1].clone().then((obj) => {
      obj.set({ opacity: 1 }); // Reset opacity to make the image fully opaque
      scaleObject(obj, scale);
      upload.insertAt(0, obj);
    })
    : null;

  const img2 = state.secondImage ?
    state.secondImage[1].clone().then((obj) => {
      obj.set({ opacity: 1 }); // Reset opacity to make the image fully opaque
      scaleObject(obj, scale);
      upload.insertAt(1, obj);
    })
    : null;

  await Promise.all([img1, img2]);

  upload.renderAll();

  return new Promise<Blob | null>(resolve => upload.getElement().toBlob(resolve));
}

async function getPositionedImage(state: ImageEditorState): Promise<Blob | null> {
  const image = state.firstImage?.[1];
  if (!image) return null;

  const width = state.canvas.width;
  const height = state.canvas.height;
  const upload = new StaticCanvas(undefined, {
    width: width,
    height: height,
    enableRetinaScaling: false
  });

  try {
    // Clone the current image with its transformations
    const clonedImage = await image.clone();
    upload.add(clonedImage);
    upload.renderAll();

    return new Promise<Blob | null>(resolve => upload.getElement().toBlob(resolve));
  } catch (error) {
    console.error('Error creating positioned image:', error);
    return null;
  }
}

async function uploadResult(state: ImageEditorState, blob: Blob) {
  const formData = new FormData();
  formData.append('file', blob);
  formData.append('type', 'result');
  formData.append('project', state.project);
  formData.append('assetType', state.assetType.toString());
  if (state.secondImage?.[0]) {
    formData.append('asset', state.secondImage?.[0]);
  }

  const response = await fetch('/image-editor-upload-result', {
    method: 'POST',
    body: formData
  });

  if (response.ok) {
    window.htmx.trigger('#image-editor-refresh-' + state.id, 'click');
  } else {
    const errorText = await response.text();
    alert(errorText);
  }
}

async function moveResult(state: ImageEditorState) {
  const formData = new FormData();
  formData.append('type', 'result');
  formData.append('project', state.project);
  formData.append('assetType', state.assetType.toString());
  if (state.secondImage?.[0]) {
    formData.append('asset', state.secondImage?.[0]);
  }

  const response = await fetch('/image-editor-move-result', {
    method: 'POST',
    body: formData
  });

  if (response.ok) {
    window.htmx.trigger('#image-editor-refresh-' + state.id, 'click');
  } else {
    const errorText = await response.text();
    alert(errorText);
  }
}

async function uploadSupport(state: ImageEditorState, blob: Blob, type: 'mask' | 'positioning', event: Event) {
  const formData = new FormData();
  formData.append('file', blob);
  formData.append('type', type);
  formData.append('project', state.project);
  formData.append('assetType', state.assetType.toString());

  const response = await fetch('/image-editor-upload-support', {
    method: 'POST',
    body: formData
  });

  if (response.ok) {
    window.htmx.trigger('#image-editor-refresh-' + state.id, 'click');
    if (type === 'mask') {
      window.PLImageEditorClear(state.id, event);
    }
  } else {
    const errorText = await response.text();
    alert(errorText);
  }
}

console.log('PLImageEditor has been installed');
