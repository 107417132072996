export function hardcoded(s: string): string {
  return s;
}

export function updateRoot(root: HTMLElement | null, zoomLevel: number, move: [number, number], additional?: (root: HTMLElement) => void) {
  if (root) {
    root.style.transform = 'scale(' + zoomLevel + ')' + 'translate(' + move[0] + 'px,' + move[1] + 'px)';
    if (additional) {
      additional(root)
    }
  }
}

export function swallow(event: Event) {
  event.preventDefault();
  event.stopPropagation();
}

export function setLabelText(id: string, separator: string, text: string) {
  const textId = id + separator + 'text';
  const input = document.getElementById(textId);
  if (input) {
    input.innerText = text;
  }
}

export function hideLabelText(id: string, separator: string) {
  const textId = id + separator + 'text';
  const input = document.getElementById(textId);
  if (input) {
    input.innerText = "";
  }
}

export function hideInputData(id: string, separator: string) {
  const titleId = id + separator + 'title';
  const hintId = id + separator + 'hint';
  hideLabelText(titleId, separator)
  hideLabelText(hintId, separator)
}

export function setSelectInputData(id: string, separator: string, data: any) {
  const titleId = id + separator + 'title';
  setLabelText(titleId, separator, data.title);
  const select = document.getElementById(id) as HTMLSelectElement;
  select.options.length = 0;
  for (const item of data.items) {
    const option = document.createElement('option');
    option.value = item.header;
    option.innerText = item.title;
    select.appendChild(option);
  }
}

export function setTextInputData(id: string, separator: string, data: any) {
  const titleId = id + separator + 'title';
  const hintId = id + separator + 'hint';
  setLabelText(titleId, separator, data.title);
  setLabelText(hintId, separator, data.hint);
}

export function setButtonTitle(id: string, separator: string, text: string) {
  const titleId = id + separator + 'title';
  const input = document.getElementById(titleId);
  if (input) {
    input.innerText = text;
  }
}

export function activateButton(id: string) {
  const button = document.getElementById(id);
  button?.classList.add('active');
  button?.setAttribute('aria-pressed', 'true');
}

export function inactivateButton(id: string) {
  const button = document.getElementById(id);
  button?.classList.remove('active');
  button?.setAttribute('aria-pressed', 'false');
}

export function setParagraphText(id: string, text: string) {
  const input = document.getElementById(id);
  if (input instanceof HTMLParagraphElement) {
    input.textContent = text;
  }
}

export function setInputText(id: string, text: string, callback: ((text: string) => void) | undefined = undefined) {
  const input = document.getElementById(id);
  if (input instanceof HTMLInputElement) {
    input.value = text;
    if (callback) {
      let debounceTimeout: NodeJS.Timeout | undefined;
      input.addEventListener('input', () => {
        if (debounceTimeout) {
          clearTimeout(debounceTimeout);
        }
        debounceTimeout = setTimeout(() => {
          callback(input.value);
        }, 300);
      });
    }
  }
}
