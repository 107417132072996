import 'bootstrap/scss/bootstrap.scss'

import htmx from "htmx.org/dist/htmx.esm";
import './PLGallery.js'
import './PLFloorPlanEditor.ts'
import './PLImageEditor.ts'
import './PLListGroup.js'
import './PLForm.ts'
import './PLInput.ts'
import './PLMap.js'
import './PLModal.ts'
import './PLMovie.ts'
import './PLPageControl.ts'
import './PLSelectInput.ts'
import './PLTable.js'
import './PLTextEditor.ts'
import './PLToast.ts'
import './HXDisableTarget.ts'
import './PLCard.ts'

window.htmx = htmx;

window.PLGlobalsInstall = function () {
	htmx.defineExtension('proplab-use-search', {
		onEvent: function (name, evt) {
			if (name === "htmx:configRequest") {
				let search = evt.detail.parameters['search'];
				delete evt.detail.parameters['search'];
				let url = new URL(evt.detail.path, window.location.href);
				url.searchParams.set("search", search);
				evt.detail.path = url.toString();
			}
		}
	});

	window.PLModalInit();
}

window.PLGlobalsConfirm = function (target, event, dialogTitle, dialogText, dialogPositiveBtn, dialogNegativeBtn) {
	const dialog = document.getElementById("pl-globals-confirm-dialog")

	const positiveBtn = dialog.querySelector(".btn.btn-sm.btn-primary.bg-primary-main");
	const closeDialog = dialog.querySelector(".btn-close");
	const negativeBtn = dialog.querySelector(".btn.btn-sm.btn-secondary.bg-secondary-main");

	const negativeBtnText = negativeBtn.querySelector('div')
	const positiveBtnText = positiveBtn.querySelector('div')
	const dialogTitleElement = dialog.querySelector(".modal-header");
	const dialogTextElement = dialog.querySelector('.pl-md-regular');

	dialog.showModal()
	dialogTitleElement.innerText = dialogTitle
	dialogTextElement.innerText = dialogText
	positiveBtnText.innerText = dialogPositiveBtn
	negativeBtnText.innerText = dialogNegativeBtn

	const onPositive = function (e) {
		const callback = document.getElementById(target);
		if (callback)
			htmx.trigger(callback, event);
		dialog.close();
	};
	const onNegative = function (e) {
		dialog.close();
	};

	positiveBtn.addEventListener("click", onPositive);
	closeDialog.addEventListener("click", onNegative);
	negativeBtn.addEventListener("click", onNegative);

	const observer = new MutationObserver((mutationsList, observer) => {
		for (let mutation of mutationsList) {
			if (mutation.type === 'attributes' && mutation.attributeName === 'open') {
				if (!dialog.hasAttribute('open')) {
					positiveBtn.removeEventListener("click", onPositive);
					closeDialog.removeEventListener("click", onNegative);
					negativeBtn.removeEventListener("click", onNegative);
					observer.disconnect();
				}
			}
		}
	});
	observer.observe(dialog, { attributes: true, attributeFilter: ['open'] });
}

window.PLGlobalsConfirmWithInput = function (target, event, dialogTitle, confirmText, hintText, dialogPositiveBtn, dialogNegativeBtn) {
	const dialog = document.getElementById("pl-globals-confirm-with-input-dialog")
	const closeDialog = dialog.querySelector(".btn-close");
	const negativeBtn = dialog.querySelector(".btn.btn-sm.btn-secondary.bg-secondary-main");
	const negativeBtnText = negativeBtn.querySelector('div')
	const positiveBtn = dialog.querySelector(".btn.btn-sm.btn-primary.bg-primary-main");
	const positiveBtnText = positiveBtn.querySelector('div')
	const dialogTitleElement = dialog.querySelector(".modal-header");
	const conformTextElement = dialog.querySelector('.pl-text-input-title');
	const hintTextElement = dialog.querySelector('.pl-text-input-hint');

	dialog.showModal()
	dialogTitleElement.innerText = dialogTitle
	conformTextElement.innerText = confirmText
	hintTextElement.innerText = hintText
	positiveBtnText.innerText = dialogPositiveBtn
	negativeBtnText.innerText = dialogNegativeBtn

	positiveBtn.addEventListener("click", () => {
		const callback = document.getElementById(target)
		if (callback) {
			const inputValue = dialog.querySelector('input');
			const jsonValue = JSON.stringify({ value: inputValue.value });

			callback.setAttribute("hx-vals", jsonValue)
			inputValue.value = "";
			htmx.trigger(callback, event)
		}
		dialog.close()
	})

	closeDialog.addEventListener("click", () => {
		dialog.close()
	})

	negativeBtn.addEventListener("click", () => {
		dialog.close()
	})
}

window.PLGlobalsPrint = function (id) {
	const originalContent = document.getElementById(id).innerHTML;
	const originalHead = document.head.innerHTML;
	const printWindow = window.open("", "_blank", "width=800,height=600");

	printWindow.document.write(`
    <html>
      <head>
        ${originalHead}
      </head>
      <body>
        ${originalContent}
      </body>
    </html>
  `);

	printWindow.document.close();
	printWindow.onload = function () {
		function triggerPrint() {
			printWindow.focus();
			printWindow.onafterprint = function () {
				printWindow.close();
			};
			printWindow.print();
		}

		setTimeout(function () {
			triggerPrint();
		}, 1000);
	};
}

window.PLGlobalsHome = function () {
	window.location.href = "https://proplab.ai/";
}

window.PLGlobalsHelp = function () {
	const url = "https://help.proplab.ai/";
	window.open(url, "_blank");
}

window.PlGlobalIsPortrait = function () {
	if (!window._PlGlobalIsPortraitInitialized) {
		document.body.addEventListener('htmx:afterSwap', handleAfterSwap);
		window._PlGlobalIsPortraitInitialized = true;
	}

	if (!window._PlGlobalResizeInitialized) {
		window.addEventListener("resize", handleAfterSwap);
		window._PlGlobalResizeInitialized = true;
	}
};

function handleAfterSwap(event) {
	const element = document.getElementById("isPortrait");
	if (element) {
		const isPortrait = window.matchMedia("(orientation: portrait)").matches;
		const isSmallScreen = checkIfTablet();

		if (isPortrait && isSmallScreen && element) {
			element.style = "display: block; z-index: 300;"
		} else if (!isPortrait && element) {
			element.style.display = "none";
		}
	}
}

function checkIfTablet() {
	const userAgent = navigator.userAgent.toLowerCase();
	const isTabletUA = /ipad|tablet|playbook|kindle|mobile|iphone|ipod|android/i.test(userAgent);
	return isTabletUA;
}

console.log('PLGlobals has been installed');

