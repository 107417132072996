/// <reference path="./custom-window.d.ts" />
import { Offcanvas } from 'bootstrap';

window.PLDrawerInstall = function (id, separator) {
  const drawerId = id + separator + 'drawer';
  const element = document.getElementById(drawerId) as HTMLElement;

  if (!element) {
    return;
  }

  let offcanvas = Offcanvas.getInstance(element);
  if (!offcanvas) {
    offcanvas = new Offcanvas(element, { backdrop: true, keyboard: false });
  }

  element.addEventListener('hide.bs.offcanvas', (event) => {
    event.preventDefault();
  });

  offcanvas.show();
};

window.PLDrawerClose = function (id, separator) {
  console.log('PLDrawer close, ');
  const drawerId = id + separator + 'drawer';

  const element = document.getElementById(drawerId) as HTMLElement;
  let offcanvas = Offcanvas.getInstance(element);
  if (offcanvas) {
    element.addEventListener('hide.bs.offcanvas', () => { console.log('PLDrawer is hiding'); });
  }
}

console.log('PLDrawer has been installed');