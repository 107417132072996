/// <reference path="./custom-window.d.ts" />

import {hardcoded} from "./Utils";

window.PLInputInstall = function (id, kind, message, separator) {
  console.log('PLInput install, kind=' + kind + ', message=' + message);

  const input = document.getElementById(id) as HTMLInputElement | null;
  if (input) {
    if (kind === 'invalid')
      input?.setCustomValidity(message ?? hardcoded("Unknown error"));
    else
      input?.setCustomValidity("");

    input.addEventListener("input", () => {
      input.setCustomValidity("");
    });
  }
}

console.log('PLInput has been installed');
