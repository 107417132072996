/// <reference path="./custom-window.d.ts" />
import lottie from "lottie-web"

window.PLAnimation = function (src, id) {
  const element = document.getElementById(id)

  if (element !== null) {
    if (element.children.length !== 0) {
      Array.from(element.children).forEach((child) => {
        element.removeChild(child)
      });
    }
    lottie.loadAnimation({
      container: element,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: src
    })
  }
}
console.log('PLAnimation installed')