/// <reference path="./custom-window.d.ts" />

import MuxPlayerElement from "@mux/mux-player";

window.PLMovieInstall = function (id, playbackId, token, autoPlay, separator) {
  console.log('PLMovie install, playbackId=' + playbackId + ', token=' + token);

  const player = new MuxPlayerElement();
  player.playbackId = playbackId;
  player.playbackToken = token;

  const element = document.getElementById(id);
  element?.append(player);

  if (autoPlay && !player.hasPlayed) {
    element?.classList?.add("hideMuxControls");
    setTimeout(() => {
      if (element) {
        player.muted = true;
        player.play().catch(error => {
          console.warn('Autoplay failed', error);
        });
      }
    }, 1000);
  }
}

console.log('PLMovie has been installed');
